import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import navbar_shower from "./navbar_shower";
import rh_agents from "./rh_agents";
import router from "@/router";

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    missionAgent: null,
    temporalBudget: null,
    infoTampon: null,
    selectedStock: null,
    oldBudgetSession: null,
    selectedYear: null,
    path: null,
    formationList: [],
    allRecrutement: [],
    temporalCongeActe: null,
    agent: null,
    shareInfo: null,
    redirect: null,
    temporaryInfo: null,
    marketUpdateInfo: null,
    billItemSelected: null,
    validateItemSelected: null,
    paiementItemSelected: null,
    right: null,
    validatePage: 1,
  },

  mutations: {
    save_right(state, position) {
      state.right = position;
    },
    save_validatePage(state, position) {
      state.validatePage = position;
    },
    save_temporary_info(state, position) {
      state.temporaryInfo = position;
    },
    save_market_update_info(state, position) {
      state.marketUpdateInfo = position;
    },
    save_bill_item_selected(state, position) {
      state.billItemSelected = position;
    },
    save_validate_item_selected(state, position) {
      state.validateItemSelected = position;
    },
    save_paiement_item_selected(state, position) {
      state.paiementItemSelected = position;
    },
    save_formation(state, position) {
      state.formationList = position;
    },

    save_redirect(state, position) {
      state.redirect = position;
    },

    save_share(state, position) {
      state.shareInfo = position;
    },

    save_token(state, position) {
      state.token = position;
    },

    save_selected_year(state, position) {
      state.selectedYear = position;
    },

    save_selected(state, position) {
      state.selectedStock = position;
    },

    save_old_budget(state, position) {
      state.oldBudgetSession = position;
    },

    save_tampon(state, position) {
      state.infoTampon = position;
    },

    save_budget(state, position) {
      state.temporalBudget = position;
    },

    save_user(state, position) {
      state.user = position;
    },

    save_agent(state, position) {
      state.agent = position;
    },

    save_mission_agent (state, position) {
      state.missionAgent = position;
    },

    save_path (state, position) {
      state.path = position;
    },

    save_conge_act (state, position) {
      state.temporalCongeActe = position;
    },

    logout(state, val) {
      state.token = val;
      state.user = val;
      state.missionAgent = val;
      state.temporalBudget = val;
      state.infoTampon = null;
      state.selectedStock = null;
      state.oldBudgetSession = null;
      state.selectedYear = null;
      state.path = null;
      state.allRecrutement = [];
      state.temporaryInfo = null;
      state.marketUpdateInfo = null;
      state.billItemSelected = null;
      state.validateItemSelected = null;
      state.paiementItemSelected = null;
      state.validatePage = null;
      state.right = null;
      state.redirect = null;
      state.shareInfo = null;
      router.push('/login')
    },
  },

  actions: {
    saveRight({ commit }, c) {
      commit("save_right", c);
    },
    saveValidatePage({ commit }, c) {
      commit("save_validatePage", c);
    },
    saveTemporaryInfo({ commit }, c) {
      commit("save_temporary_info", c);
    },
    saveMarketUpdateInfo({ commit }, c) {
      commit("save_market_update_info", c);
    },
    saveBillItemSelected({ commit }, c) {
      commit("save_bill_item_selected", c);
    },
    saveValidateItemSelected({ commit }, c) {
      commit("save_validate_item_selected", c);
    },
    savePaiementItemSelected({ commit }, c) {
      commit("save_paiement_item_selected", c);
    },
    saveCongeAct({ commit }, c) {
      commit("save_conge_act", c);
    },
    saveRedirect({ commit }, c) {
      commit("save_redirect", c);
    },
    saveShareData({ commit }, c) {
      commit("save_share", c);
    },
    saveOldFornmation({ commit }, c) {
      commit("save_formation", c);
    },
    saveOldBudget({ commit }, c) {
      commit("save_old_budget", c);
    },
    saveselectedYear({ commit }, c) {
      commit("save_selected_year", c);
    },
    saveSelected({ commit }, c) {
      commit("save_selected", c);
    },
    saveToken({ commit }, c) {
      commit("save_token", c);
    },
    saveTampon({ commit }, c) {
      commit("save_tampon", c);
    },
    saveBudget({ commit }, c) {
      commit("save_budget", c);
    },
    mission_agent({ commit }, c) {
      commit("save_mission_agent", c);
    },
    saveUser({ commit }, c) {
      commit("save_user", c);
    },
    saveAgent({ commit }, c) {
      commit("save_agent", c);
    },
    savePath({ commit }, c) {
      commit("save_path", c);
    },
    logout({ commit }, e) {
      commit("logout", e);
    },
  },

  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    missionAgent: (state) => state.missionAgent,
    temporalBudget: (state) => state.temporalBudget,
    infoTampon: (state) => state.infoTampon,
    selectedStock: (state) => state.selectedStock,
    oldBudget: state => state.oldBudgetSession,
    selectedYear: state => state.selectedYear,
    selectedPath: state => state.path,
    formationPath: state => state.formationList,
    recrutementList: state => state.allRecrutement,
    temporalCongeActe: state => state.temporalCongeActe,
    agent: (state) => state.agent,
    trackShareData: (state) => state.shareInfo,
    redirectData: (state) => state.redirect,
    temporaryData: (state) => state.temporaryInfo,
    marketUpdateData: (state) => state.marketUpdateInfo,
    billItemData: (state) => state.billItemSelected,
    validateItemData: (state) => state.validateItemSelected,
    paiementItemData: (state) => state.paiementItemSelected,
    getRight: (state) => state.right,
    validatePage: (state) => state.validatePage,
  },

  modules: {
    navbar_shower,
    rh_agents,
  },
});

export default vuex;
