
export const initialState = () => ({
    agents: null,
    jobs: null,
    new_agent_info: {},
    new_agent_files:{

    }
})

import mutations from "./mutations"
import actions from "./actions"
export default {

    namespaced: true,

    state(){
        return initialState()
    },

    mutations,
    actions

}
