import { initialState } from "."
export default {

    RESET_STATE(state){
        let s = initialState()
        for(let key in s)
            state[key] = null
    },

    SET_AGENTS(state,value) {
        state.agents = value
    },

    SET_JOBS(state,value) {
        state.jobs = value
    },

    ADD_AGENT(state, value){
        state.agents.push(value)
    },

    UPDATE_AGENT(state, value){
        state.agents = state.agents.map( (agent) => {
            if(agent.id == value.id)
                return value
            else
                return agent
        })
        console.log(state.agents)
    },

    DELETE_AGENT(state, id){
        state.agents = state.agents.filter( agent => agent.id !== id )
    },

    RESET_NEW_AGENT_INFO(state){
      state.new_agent_info = {}
    },

    SET_NEW_AGENT_INFO(state, value){
      state.new_agent_info[value.step] = value.info
    },

    SET_NEW_AGENT_FILES(state, value){
      state.new_agent_files[value.step]= value.files
    },

}
