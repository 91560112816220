export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’ai oublié mon mot de passe"])},
  "presidency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Présidence de la République du Bénin - "])},
  "forgetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "forgetEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié? Entrez votre email ci-dessous et vous recevrez des instructions pour récupérer votre compte"])},
  "getMyAccountButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mon compte"])},
  "loginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de connexion"])},
  "recoveryLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de récupération"])},
  "sendRecoveryLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un lien de récupération par mail. \nConsultez votre boîte mail et suivez les instructions"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "definePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir votre nouveau mot de passe"])},
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer "])},
  "recoverPasswordButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mon mot de passe"])},
  "passwordRecovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe réinitialisé"])},
  "passwordIsRecover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été réinitialisé avec succès. \nConnectez-vous pour accéder à votre espace de travail."])},
  "createPosteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un poste"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "justForPush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juste pour push"])}
}