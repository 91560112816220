import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store/store";
import router from "./router";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import install from "./install";
import i18n from "./i18n";
import easySpinner from "vue-easy-spinner";
import Switches from "vue-switches";
import vClickOutside from "click-outside-vue3";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import Toaster from "@incuca/vue3-toaster";
import VueClipboard from 'vue3-clipboard'
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

createApp(App)
  .use(i18n)
  .use(install)
    .use(Toaster)
  .use(easySpinner, {
    /*options*/
    prefix: "easy",
  })
    .use( CKEditor )
    .use(VueApexCharts)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
  .use(VCalendar, {})
  .use(vClickOutside)
    .use(VueApexCharts)
  .use(Switches)
    .component('VueDatePicker', VueDatePicker)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(store)
  .use(router)

  .mount("#app");
