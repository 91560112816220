export default {
  baseURL: process.env.VUE_APP_USER_BASE_URL || 'https://gouv-users.41devs.com/api/v1/',
  ssoBaseUrl: process.env.VUE_APP_SSO_BASE_URL || 'https://pprodofficial.service-public.bj/api/official/token',
  baseUpload: process.env.VUE_APP_UPLOAD_BASE_URL || 'https://documinio.41devs.com/api/v1/',
  login: 'users/auth/login',
  ldaplogin: 'users/auth/login/ldap',
  changePass: 'users',
  uploadFile: 'uploader/upload',
  activateAgent: (id) => `users/${id}/activate`,
  getUser: 'users',
  createAdmin: 'users/admin',
  createAgency: 'users/agency',
  desactiver: (id) => `users/${id}/deactivate`,
  activate: (id) => `users/${id}/activate`,
  updateUser: (id) => `users/${id}`,
  addUsers: 'users/by-admin',
  getUserByRole: 'users/filter-by-roles',
  addPermission: (id) => `users/${id}/add-permissions`,
  addRole: (id) => `users/${id}/add-roles`,
  removRole: (id) => `users/${id}/remove-roles`,
  deleteUser: (id) => `users/${id}`,
}
