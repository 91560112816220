import { createWebHistory, createRouter } from "vue-router";
import pathLoader from "../plugins/loader";
import store from "../store/store";
import http from "@/plugins/https";

const routes = [
  {
    path: "/login",
    name: "login",
    component: pathLoader("login"),
  },
  {
    path: "/api/v1/users/auth/ldap/callback/:token",
    name: "inter",
    component: pathLoader("inter"),
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: pathLoader("forgetPassword"),
  },
  {
    path: "/set-password",
    name: "newPassword",
    component: pathLoader("newPassword"),
    meta: {
      token: true,
    },
  },
  {
    path: "/dashboard",
    name: "homePrivate",
    component: pathLoader(null, "homePrivate"),
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: pathLoader("rh"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/document-viewer",
        name: "documents",
        component: pathLoader("documents-page"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/annuaire",
        name: "annuaire",
        component: pathLoader("annuaire"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/integration",
        name: "integration",
        component: pathLoader("integration"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/directions",
        name: "directions",
        component: pathLoader("directions"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/retraites",
        name: "retraites",
        component: pathLoader("retraites"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/postes",
        name: "postes",
        component: pathLoader("postes"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/equipes",
        name: "equipes",
        component: pathLoader("equipes"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/services",
        name: "services",
        component: pathLoader("services"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/offres",
        name: "offres",
        component: pathLoader("offres"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/organigramme",
        name: "organigramme",
        component: pathLoader("organigramme"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ac/actes",
        name: "actes",
        component: pathLoader("actes"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ac/archivage-acte",
        name: "archivage-acte",
        component: pathLoader("archivage-acte"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pd/sanctions",
        name: "sanctions",
        component: pathLoader("sanctions"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pd/contentieux",
        name: "contentieux",
        component: pathLoader("contentieux"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gpr/prime-simulateur",
        name: "primeSimulateur",
        component: pathLoader("prime-simulateur"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gpr/prime-traitement",
        name: "primeTraitement",
        component: pathLoader("prime-traitement"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gpr/prime-cloture",
        name: "primeCloture",
        component: pathLoader("prime-cloture"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gpr/prime-mouvement",
        name: "primeMouvement",
        component: pathLoader("prime-mouvement"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gpr/prime-element",
        name: "primeElement",
        component: pathLoader("prime-element"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/creationOffre",
        name: "creationOffre",
        component: pathLoader("creationOffre"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/formations",
        name: "formations",
        component: pathLoader("formations"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evaluations",
        name: "evaluations",
        component: pathLoader("evaluations"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ca/approbations",
        name: "approbations",
        component: pathLoader("approbations"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ca/conges",
        name: "conges",
        component: pathLoader("newConges"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ca/ferie",
        name: "ferie",
        component: pathLoader("ferie"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partager",
        name: "documents-shared",
        component: pathLoader("documents-shared"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/as/cotisations",
        name: "cotisations",
        component: pathLoader("cotisations-sociales"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/as/couvertures",
        name: "couvertures",
        component: pathLoader("couvertures-sante"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/as/care",
        name: "care",
        component: pathLoader("care"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/planning",
        name: "planning",
        component: pathLoader("planning"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/oe/candidats",
        name: "candidats",
        component: pathLoader("candidats"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/document",
        name: "document",
        component: pathLoader("document"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/archives",
        name: "archives",
        component: pathLoader("archives"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/decaissements",
        name: "decaissement",
        component: pathLoader("decaissement"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/dashboard",
        name: "finance-dahsboard",
        component: pathLoader("finances"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/fournisseurs",
        name: "gf-fournisseurs",
        component: pathLoader("finance-fournisseurs"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/fournisseurs/create",
        name: "gf-create-fournisseurs",
        component: pathLoader("finance-fournisseurs", "create"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/plans",
        name: "plan-budgetaire",
        component: pathLoader("plan-budgetaire"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/validations",
        name: "gf-validation",
        component: pathLoader("finance-validations"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/encaissements",
        name: "gf-encaissements",
        component: pathLoader("finance-encaissements"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/apercu",
        name: "aperçu",
        component: pathLoader("aperçu"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/bulletins",
        name: "bulletins",
        component: pathLoader("bulletin-paie"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/fiche",
        name: "ficheAgent",
        component: pathLoader("ficheAgent"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/simulateur",
        name: "simulateur",
        component: pathLoader("simulator"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/cloture",
        name: "cloture",
        component: pathLoader("cloture-paie"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ep/etat-de-paiement",
        name: "etatDePaiment",
        component: pathLoader("etat-de-paiement"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ep/cloture-etat-de-paiement",
        name: "clotureEtatDePaiment",
        component: pathLoader("etat-de-paiement-cloture"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/paie",
        name: "paie",
        component: pathLoader("element-paie"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gp/reversement",
        name: "reversement",
        component: pathLoader("reversement"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/budgets",
        name: "gf-budgets",
        component: pathLoader("finance-budgets"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/missions",
        name: "fn-finance-missions",
        component: pathLoader("finance-missions"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/expression-besoin",
        name: "expressionBesoin",
        component: pathLoader("expression-besoins"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/marches",
        name: "gf-marches",
        component: pathLoader("finance-marches"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/add-marches",
        name: "gf-add-marches",
        component: pathLoader("finance-marches-add"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/details-marches",
        name: "gf-details-marches",
        component: () => import('@/views/finance-marches/details.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/bu/previsionnels",
        name: "bu-previsionnels",
        component: pathLoader("finance-previsionnels"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/bu/budgetLine",
        name: "bu-budgetLine",
        component: pathLoader("budgetLine"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/nouvelle-mission",
        name: "fn-nouvelle-mission",
        component: pathLoader("nouvelle-mission"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/prestataires",
        name: "gf-finance-prestataires",
        component: pathLoader("finance-prestataires"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/bu/execution',
        name: 'bu-execution',
        component: pathLoader("budget-execution"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: '/bu/execution/financement',
        name:'bu-execution-finacement',
        component: () => import('@/views/budget-execution/source-financement.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/bu/execution/ordre-paiement',
        name:'ordre-paiement-details',
        component: () => import('@/views/budget-execution/ordre-paiement.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/bu/pta',
        name: 'bu-pta',
        component: pathLoader("budget-pta"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/gf/paiements",
        name: "gf-finance-paiements",
        component: pathLoader("finance-paiements"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/paiements-missions",
        name: "gf-finance-paiements-missions",
        component: pathLoader("paiement-missions"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rd/nouvelEtat",
        name: "nouvelEtat",
        component: pathLoader("nouvelEtat"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/depenses",
        name: "gf-finance-depenses",
        component: () => import('@/views/finance-depenses'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/finance-depenses",
        name: "gf-depenses",
        component: () => import('@/views/depenseFacture'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/finance-validation-facture",
        name: "gf-validation-facture",
        component: () => import('@/views/validationFacture'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/finance-validation-facture/details/:id",
        name: "gf-validation-facture-details",
        component: () => import('@/components/container/validation-facture/bill-details.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/finance-paiements",
        name: "gf-paiements",
        component: () => import('@/views/paiementFacture'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gf/finance-cloture-facture",
        name: "gf-cloture-facture",
        component: () => import('@/views/clotureFacture'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/missions",
        name: "fn-finance-missions",
        component: () => import('@/views/finance-missions'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/missions/details/:id",
        name: "fn-finance-missions-details",
        component: ()=> import('@/components/container/missions/details.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/missions/etat/details/:id",
        name: "fn-finance-missions-etat-details",
        component: ()=> import('@/components/container/missions/etat/details.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/avance",
        name: "co-avance-de-fonds",
        component: () => import('@/views/avance-de-fonds'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/fn/titres",
        name: "co-titres-de-recettes",
        component: () => import('@/views/titres-de-recettes'),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/evaluation_view",
        name: "evaluationView",
        component: () => import('@/views/evaluationsView'),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/titres_de_paiements",
        name: "titres_de_paiements",
        component: () => import('@/views/titresPaiements'),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/requetes",
        name: "requetes",
        component: pathLoader("requetes"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/utilisateurs",
        name: "utilisateurs",
        component: pathLoader("utilisateurs"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/userGestion",
        name: "userGestion",
        component: pathLoader("userGestion"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/agentGestion",
        name: "agentGestion",
        component: () => import('@/views/userGestion/agentGestion'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/utilisateurGestion",
        name: "utilisateurGestion",
        component: () => import('@/views/userGestion/utilisateurGestion'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/administrateur",
        name: "administrateur",
        component: () => import('@/views/userGestion/administrateur'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/agencyUser",
        name: "agencyUser",
        component: () => import('@/views/userGestion/agencyUser'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/addUser",
        name: "addUser",
        component: () => import('@/views/userGestion/addUser'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pr/directions",
        name: "directions-pr",
        component: () => import('@/views/parametres/directions'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pr/corps",
        name: "corps-pr",
        component: () => import('@/views/parametres/corps'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pr/banques",
        name: "banque-pr",
        component: () => import('@/views/parametres/banque'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pr/assurances",
        name: "assurances-pr",
        component: () => import('@/views/parametres/assurances'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/logs",
        name: "logs-pr",
        component: () => import('@/views/logs'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/addPermission",
        name: "addPermission",
        component: () => import('@/views/userGestion/addUserPermission'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/updateUser",
        name: "updateUser",
        component: () => import('@/views/userGestion/updateUser'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/userGestion/factureGestion",
        name: "factureGestion",
        component: () => import('@/views/userGestion/factureGestion'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ferie",
        name: "ferier-pr",
        component: () => import('@/views/ferie'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/titre_de-paiement/financement',
        name:'bu-titre-finacement',
        component: () => import('@/views/budget-execution/source-financement.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/rh/nouvel-agent",
        name: "nouvel-agent",
        component: pathLoader("newAgent"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rh/archive",
        name: "agent-archive",
        component: pathLoader("archive"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/",
    name: "FirstPage",
    component: pathLoader(null, "homeFirst"),
    redirect: "first-page",
    children: [
      {
        path: "/first-page",
        name: "first-page",
        component: pathLoader("first-page"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/document-viewer",
    name: "DocumentViewer",
    component: pathLoader(null, "documentViewer"),
    redirect: "document-viewer",
    children: [
      {
        path: "/document-viewer",
        name: "documents",
        component: pathLoader("documents-page"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/details-budget",
    name: "singleDetailView",
    component: pathLoader(null, "singleNavView"),
    redirect: "details-budget",
    children: [
      {
        path: "/details-budget",
        name: "details-budget",
        component: pathLoader("details-budget"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "pta",
        name: "details-pta",
        component: () => import('@/views/budget-pta/details-pta.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "pta/institution",
        name: "details-pta-institution",
        component: () => import('@/views/budget-pta/details-pta-institution.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/pta-view',
        name: "pta-view",
        component: () => import('@/views/budget-pta/pta-view.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },

  {
    path: '/agency-view',
    name: "agency",
    component: () => import('@/views/agency/index'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pta-view-agency',
    name: "pta-view",
    component: () => import('@/views/budget-pta/pta-view.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plan-de-travail',
    name: "plan-de-travail",
    component: () => import('@/views/budget-pta/plan-de-travail.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/global-budget',
    name: "global-budget",
    component: () => import('@/views/finance-previsionnels/globalBudget.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vue-globale',
    name: "vue-globale",
    component: () => import('@/views/details-budget/vueGlobale'),
    meta: {
      requiresAuth: true,
    },
  },

  // {
  //   path: "/details-pta",
  //   name: "singleNavView",
  //   component: pathLoader(null, "singleNavView"),
  //   redirect: "details-pta",
  //   children: [
  //     {
  //       path: "/details-pta",
  //       name: "details-pta",
  //       component: () => import('@/views/budget-pta/details-pta.vue'),
  //       meta: {
  //         requiresAuth: true,
  //       },
  //     },
  //     {
  //       path: "institution",
  //       name: "details-pta-institution",
  //       component: () => import('@/views/budget-pta/details-pta-institution.vue'),
  //       meta: {
  //         requiresAuth: true,
  //       },
  //     },
  //   ]
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let code = window.location.search

    if (store.getters.token) {
      const user = store.getters.user
      if (user.tokenStarter) {
        http.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token
        const dayDif = new Date().getTime() - new Date(user.tokenStarter).getTime()
        const totalHour = (dayDif / 1000)/3600

        if (totalHour < 24) {
          next();
          return;
        } else {
          store.dispatch('logout')
          router.push('/login')
        }

      } else {
        store.dispatch('logout')
        router.push('/login')
      }
    } else  {
      if (code) {
        store.dispatch('saveRedirect', window.location.search)
      }

      next("/login");
    }

  } else {
    if (to.matched.some((record) => record.meta.token)) {
      const query = window.location.search
      if (query) {
        if (query.indexOf('id') > 0 && query.indexOf('token') > 0) {
          next();
        }
      }

    } else {
      next();
    }

  }
});

export default router;
