import axios from "axios";
import apiRouter from "../router/api-routes";
// import store from "@/store/store";


const http = axios.create({
  baseURL: apiRouter.baseURL,
});

http.defaults.headers.common['x-api-key'] = '828c02e6-e21f-4f20-bb07-cc09eda3974a'
// http.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token

http.interceptors.request.use(
  function (request) {
    if (!request.baseURL.indexOf(apiRouter.baseURL)) {
      console.log("owner site");
    } else {
      console.log("other site");
    }
    return request;
  },
  function (error) {
    return error;
  }
);

http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(
      error.response || {
        response: { status: error.statusCode, message: error.message },
      }
    );
  }
);


export default http;
