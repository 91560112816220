<template>
  <router-view />
</template>

<style lang="scss">
@import "assets/style/sass/variables.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family;
}
#app::-webkit-scrollbar {
  display: none;
}
</style>
