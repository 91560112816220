export default {
  baseURL: process.env.VUE_APP_AGENT_BASE_URL || 'https://gouv-agent.41devs.com/api/v1/hr',
  // baseURL: 'https://185.3.94.141:3003/api/v1/hr',
  baseUrlFile: process.env.VUE_APP_UPLOAD_BASE_URL || 'https://documinio.41devs.com/api/v1/',
  secondURL: '',
  getAgent: '/agents',
  addAgent: '/agents',
  updateAgent: (id) => `/agents/${id}`,
  deleteAgent: (id) => `/agents/${id}`,
  addDocuments:(id) => `/agents/${id}/add-documents`,
  createAgentStats: '/agents/stats',
  createEvolutionStats: '/agents/evolution-stats',
  getJob: '/structures',
  contractuelInfo: (id) => `/agents/${id}/add-contractual-information`,
  salaireInfo: (id) => `/agents/${id}/add-payment-information`,
  bankInfo: (id) => `/agents/${id}/add-bank-information`,
  sendMail: '/agents/send-mail',
  getCareer: (id) => `/careers/get-agent-career/${id}`,
  getServices: '/services',
  newPromotion: (id) => `/careers/promote-agent/${id}`,
  suspendUser: (id) => `/agents/${id}/suspend`,
  licencedUser: (id) => `/agents/${id}/licence`,
  unsuspendUser: (id) => `/agents/${id}/unsuspend`,
  makeAvailableUser: (id) => `/agents/${id}/make-available`,
  endContractUser: (id) => `/agents/${id}/end-contract`,
  updateBankInfo: (id) => `/agents/${id}/update-bank-information`,
  getDocument: (id) => `/agents/${id}/documents`,
  getNote: (id) => `/notes/get-agent-notes/${id}`,
  saveNote: '/notes',
  getVacationStat: (id) => `/vacations/get-agent-vacations/${id}`,
  getVacation: '/vacations',
  addFile: 'uploader/upload',
  createVacation: '/vacations',
  updateVacation: (id) => `/vacations/${id}`,
  updateContractuel: (id) => `/agents/${id}/update-contractual-information`,
  getNth: '/structures/get-nth-structures',
  unlicencedUser: (id) => `/agents/${id}/unLicence`,
  getSanction: '/litigation/get-agent-litigations',
  getAllDivisions: '/divisions',
  createDivision: '/divisions',
  updateDivision: (id) => `/divisions/${id}`,
  addDivisionMember: '/divisions/add-members',
  removeDivisionMember: '/divisions/remove-members',
  setDivisionManager: '/structures/set-manager',
  getAllActs: '/acts',
  createNewAct: '/acts',
  approveAct: (id) => `/acts/approve/${id}`,
  rejectAct: (id) => `/acts/reject/${id}`,
  getJobs: '/jobs',
  getStructure: '/structures',
  createService: '/structures',
  getStructureById: (id) => `/structures/${id}`,
  updateStructureById: (id) => `/structures/${id}`,
  generateAct: '/hr-document/gen-act',
  getAllTasks: '/tasks/get-agent-task/',
  createTasks: '/tasks',
  getAllLitigation: '/litigation/get-litigations',
  createLitigation: '/litigation/create-litigation',
  treatLitigation: '/litigation/treat-litigation',
  setTaskStatus: '/tasks/set-status/',
  addEvaluation: '/evaluations',
  makeEvaluation: '/evaluations',
  getCorps: '/agents/core/trades',
  bank: '/tools/get-tools',
  createRecruitment: '/recruitments',
  createFormation: '/training/create-training',
  getAllFormation: '/training/get-all-training',
  getRecruitments: '/recruitments',
  createFolder: (id) => `/agents/${id}/add-folder`,
  // getActById: (id) => `/hr/acts/${id}`,
  getEvaluationByYear: (year) => `/evaluations/get-by-year/${year}`,
  getEvaluationById: (id) => `/evaluations/${id}`,
  evaluationForAgent: (userId) => `/evaluations/get-meta-by-agent/${userId}`,
  getAgentByUser: (userId) =>  `/agents/get-by-user/${userId}`,
  getAllRetiring: '/careers/get-retiring-agents',
  getAgentById: (id) => `/agents/${id}`,
  archiveAct: '/acts/archives',
  getArchive: '/acts/archives',
  getActById: (id) => `/acts/${id}`,
  //getAllTasksByAgentId: (id) => `/tasks/get-agent-task/${id}`,
  getAllTasksByAgentId: "/tasks/",
  makeToFillRecruitment: (id) => `/recruitments/${id}/makeToFill`,
  cancelRecruitment: (id) => `/recruitments/${id}/cancel`,
  getAgentAllTraining: '/training/get-all-training',
  activateAgent: (id) => `/agents/${id}/activate`,
  createTools: '/tools/create-tool',
  createHolliday: '/agents/core/holidays/add',
  getHolliday: '/agents/core/holidays',
  closeLitigation: '/litigation/close-litigation',
  deleteFile: '/agents/delete-document',
  deleteFolder: '/agents/delete-folder',
  deletedStructureById: (id) => `/structures/${id}`,
  changeJob: (id) => `/agents/${id}/change-job`,
  initialiseTraining: (id) => `/careers/initiate-agent-training/${id}`,
  endTraining: (id) => `/careers/end-agent-training/${id}`,
  addFactureAuth: (id) => `/agents/${id}/add-invoice-rights`,
  holidaysByAgent: (id) => `/vacations/get-agent-vacations/${id}`,
  archiveUser: (id) => `/agents/${id}/archive`,
  unarchiveUser: (id) => `/agents/${id}/unArchive`,
  getRecruitmentById: (id) => `/recruitments/${id}`,
  addRecruitmentDirection: (id) => `/recruitments/${id}/add-directions`,
  createRecruitmentNeed: (id) => `/recruitments/${id}/create-recruitment-need`,
  getRecruitmentNeed: (id, recruitmentId) => `/recruitments/${id}/get-needs-by-direction/${recruitmentId}`,
  unintegrate: '/agents/unintegrate',
}
