/* eslint-disable */
import http from "@/plugins/https"
import apiRouteRhAgent from "@/router/rh-routes"
import { Buffer } from 'buffer'
import allFunctions from "@/components/allFunctions";

export default {

    async get_agents(ctx, query){
        try {
            let res = await http.get(apiRouteRhAgent.baseURL+apiRouteRhAgent.getAgent + query)
            if(res) {
                // ctx.commit('SET_AGENTS',res)

                // console.log('allAgent', res)
                return res;
            }

        } catch (error) {
            console.log(error)
            return error
        }
    },

    async add_agent(ctx,payload){
      try {
          // console.log(ctx, payload)

        let form_data = new FormData()

        for(let key in payload){
          if(key == 'profile'){
            if(payload[key])
              form_data.append(key,payload[key])
          } else
            form_data.append(key,payload[key])
            // console.log(form_data)
        }

        let res = await http.post(apiRouteRhAgent.baseURL+apiRouteRhAgent.addAgent,form_data)
        if(res){
          ctx.commit('ADD_AGENT',res)
          return {
            success:1,
            data: res
          }
        }

      } catch (e) {
          console.log(e)
        return {
          success: 0,
          message: e.data.error +' : '+ e.data.message
        }
      }
    },

    async add_agent_documents(ctx,payload){
      try {

        let allData = [
            {
                documents: [],
                isPersonal: true,
                isProfessional: false
            },
            {
                documents: [],
                isPersonal: false,
                isProfessional: true
            }
        ]
          // console.log('data',payload.data)

        for(let item of payload.data){
            // console.log('FILE',item)
            const url =  await allFunctions.uploadFile(item.file)

            if (url) {
                const obj = {
                    fieldname: item.fieldname,
                    size: item.file.size,
                    url: url
                }

                if (!item.file.slug) {
                    allData[0].documents.push(obj)
                } else {
                    allData[1].documents.push(obj)
                }

                if (item.file.folderId) {
                    allData[0].folderId = item.file.folderId
                    allData[1].folderId = item.file.folderId
                }
            }
        }

        for (let item in allData) {
            if (allData[item].documents.length) {
                // console.log('send')
                // console.log(allData[item])
                let res = await http.post(apiRouteRhAgent.baseURL + apiRouteRhAgent.addDocuments(payload.agent_id), allData[item])
                return {
                    success:1,
                    data: res
                }
            }
        }
      } catch (e) {
        console.log(e)
        return {
          success: 0,
          message: e.data
        }
      }
    },


    async update_agent_infos(ctx,payload){
      try {
        let res = await await http.patch(apiRouteRhAgent.baseURL+apiRouteRhAgent.updateAgent(payload.agent_id),payload.data)

        if(res){
          // ctx.commit('UPDATE_AGENT',res)
          return {
            success: 1,
            data: res
          }
        }
      } catch (e) {
        console.log(e.data)
        return {
          success: 0,
        }
      }
    },

    async update_bank_infos(ctx,payload){
        try {
            let res = await await http.put(apiRouteRhAgent.baseURL+apiRouteRhAgent.updateBankInfo(payload.agent_id),payload.data)
            if(res){
                //ctx.commit('UPDATE_AGENT',res)
                return {
                    success: 1,
                    data: res
                }
            }
        } catch (e) {
            console.log(e.data)
            return {
                success: 0,
                //message: e.data.error +' : '+ e.data.message
            }
        }
    },

    async delete_agent(ctx,payload){
      try {
        let res = await http.delete(apiRouteRhAgent.baseURL+apiRouteRhAgent.deleteAgent(payload.agent_id))
        ctx.commit('DELETE_AGENT',payload.agent_id)
        if(res)
          return { success: 1}
      } catch (e) {
        console.log(e)
        // return {
        //   success: 0,
        //   message: e.data.error +' : '+ e.data.message
        // }
      }
    },

    async get_job(ctx){
        try {
            let res = await http.get(apiRouteRhAgent.baseURL+apiRouteRhAgent.getJob+'?type=Poste&page=1&take=100000')
            if(res) {
                ctx.commit('SET_JOBS',res)

                // console.log('allJob', res)
                return res;
            }

        } catch (error) {
            console.log(error)
            return error
        }
    },
}
